import React, { Component, useRef, Fragment } from 'react';

import { ItemWarning } from '../ItemWarnings';
import ProductWarnings from '../ProductWarning';
import Loading from '../Loading';

import { formatMoney, sizeSort, toTitleCase } from '../../utils';
import { sortItemWithInventory } from '../../utils/shop';
import { H1, H5, Span } from '../helpers/Typography';
import { ArrowIcon, Col, colors, fontFamilies, Row, Table, TBody, TD, Text, TH, TR } from '@commonsku/styles';

const ResponsiveTable = React.memo(({ children, title, ...props }) => {
  /**
   * @type {React.RefObject<HTMLDivElement>}
   */
  const ref = useRef(null);
  const isScrolling = (ref.current?.clientWidth || 0) < (ref.current?.scrollWidth || 0);
  return (
    <>
      <Row>
        <Col xs sm={4}><H5 style={{ marginBottom: 8 }}>{title}</H5></Col>
        {isScrolling && <Col xs sm={8} style={{ textAlign: 'right' }}>
          <Span style={{ fontSize: 16, marginBottom: 4 }}>
            Scroll to see more
            <ArrowIcon
              direction='right'
              size='small'
              color='var(--color-neutrals-90)'
              style={{ verticalAlign: 'middle' }}
            />
          </Span>
        </Col>}
      </Row>
      <div style={{overflowX: 'auto'}} ref={ref}>
        <Table style={{borderCollapse: 'collapse', borderSpacing: 0, width: '100%'}} {...props}>{children}</Table>
      </div>
    </>
  );
});

export const isCostVisible = ic =>
  0 == ic.hidden && (
  ic.item_cost_tab !== 'INTERNATIONAL' ||
  (ic.quantity != 1 && ic.quantity != null) ||
  (ic.quantity == null && ic.percent != 0) ||
  ic.unit_cost != 0 ||
  ic.unit_price != 0 ||
  !ic.from_db);

export class ItemInfo extends Component {

  renderRetailPrice() {
    const { item, currency_symbol ,is_shop_free} = this.props;
      if( is_shop_free == 1){
        return null;
      }
    return (
     <div>
        <H5>Price</H5>
        <p>{currency_symbol}{formatMoney(item.retail_price)}</p>
      </div>
    );
  }

  renderPriceGrid() {
    const { item, buyInventory, currency_symbol, showNet = true, default_margin = 40 } = this.props;
    const visible_options = item.options.filter(o => 0 == o.hidden && 0 != o.total_units);
    const show_prices = visible_options.length && 1 == item.share_pricing;
    if (!show_prices) {
      return null;
    }

    if (!buyInventory && item.inventory_items.length > 0) {
      const min_price = Math.min(...item.inventory_items.map(ii => ii.price));
      return (
        <section>
          <H5>Price</H5>
          <p>{currency_symbol}{formatMoney(min_price)}</p>
        </section>
      );
    }

    const first_option = visible_options[0] || { item_costs: []};
    const item_costs = first_option.item_costs.filter(isCostVisible);

    return (
      <section style={{ marginBottom: 18, }}>
        <ResponsiveTable title={'Pricing'}>
          <TBody style={{ background: 'transparent' }}>
            <TR>
              <TD className="table-heading">Qty</TD>
              {visible_options.map((o, i) =>
                <TD key={i}>{o.total_units}</TD>
              )}
            </TR>
          </TBody>
          {showNet ?
            <TBody style={{ background: 'transparent' }}>
              <TR>
                <TD className="table-heading">Net</TD>
                {visible_options.map((o, i) =>
                  <TD key={i}>{currency_symbol}{formatMoney(o.breakdowns[0].unit_price)}</TD>
                )}
              </TR>
              {item_costs.length > 0 && item_costs.map((ic, i) =>
                <TR key={i}>
                  <TD>{ic.item_cost_title}</TD>
                  {visible_options.map((o, j) =>
                    <TD key={j}>{currency_symbol}{formatMoney(o.item_costs[i].unit_price)}</TD>
                  )}
                </TR>
              )}
              <TR>
                <TD className="table-heading">Margin</TD>
                <TD colSpan={visible_options.length} style={{ textAlign: visible_options.length > 1 ? 'center' : 'initial' }}>{formatMoney(default_margin, 0)}%</TD>
              </TR>
              <TR>
                <TH className="table-heading">Retail</TH>
                {visible_options.map((o, i) =>
                  <TD key={i}>{currency_symbol}{formatMoney(o.unit_cost / (1 - (default_margin / 100)))}</TD>
                )}
              </TR>
            </TBody> :
            <TBody style={{ background: 'transparent' }}>
              <TR>
                <TD className="table-heading">
                  <div>Price</div>
                  <Text style={{ color: colors.neutrals[60], fontFamily: fontFamilies.regular }}>per item</Text>
                </TD>
                {visible_options.map((o, i) =>
                  <TD key={i}>{currency_symbol}{formatMoney(o.unit_price)}</TD>
                )}
              </TR>
            </TBody>}
        </ResponsiveTable>
      </section>
    );
  }

  renderFixedCosts() {
    const { item, buyInventory, currency_symbol, showNet } = this.props;
    const fixed_costs = (item.fixed_costs ? item.fixed_costs : item.item_costs).filter(isCostVisible).filter(ic => ic.quantity);

    if (!fixed_costs || !fixed_costs.length || item.share_fixed_charges == 0) {
      return null;
    }

    if (!buyInventory && item.inventory_items.length > 0) {
      return null;
    }

    return (
      <section style={{ marginBottom: 18, }}>
        <ResponsiveTable title={`Fixed Charges ${showNet ? '(Net)' : ''}`}>
          <TBody style={{ background: 'transparent' }}>
            {fixed_costs.map((c, i) =>
              <TR key={i}>
                <TD>{c.item_cost_title}{c.item_location_title && <span> &mdash; {c.item_location_title}</span>}</TD>
                <TD>{currency_symbol}{formatMoney(c.unit_price)}</TD>
              </TR>
            )}
          </TBody>
        </ResponsiveTable>
      </section>
    );
  }

  renderInventory() {
    const { item, showInventory } = this.props;
    if (!showInventory || !item.inventory_items || !item.inventory_items.length) {
      return null;
    }

    return (
      <section>
        <H5>Inventory</H5>
        {sortItemWithInventory(item).map(
          (ii, index) => <p key={ii.inventory_item_id} style={index < item.inventory_items.length - 1 ? { marginBottom: 0 } : null}>
            {ii.description}: {Math.max(0, ii.available)}
          </p>
         )}
      </section>
    );
  }

  renderColors() {
    const { item, buyInventory } = this.props;
    if (!item.item_colors || !item.item_colors.length || 'ps-products' === item.copied_from) {
      return null;
    }

    const inventory_colors = item.inventory_items.map(ii => ii.color_id);
    const item_colors = item.item_colors.filter(c => buyInventory || inventory_colors.length === 0 || inventory_colors.includes(c.color_id));
    if (item_colors.length === 0) {
      return null;
    }

    return (
      <section>
        <H5>Colors</H5>
        <p>{item_colors.sort((a, b) => {
            if(a.color_name < b.color_name) return -1;
            if(a.color_name > b.color_name) return 1;
            return 0;
          }).map((c, i) =>
          <span key={c.item_color_id} style={{ textTransform: 'capitalize' }}>{0 === i ? c.color_name : `, ${c.color_name}`}</span>
        )}</p>
      </section>
    );
  }

  renderSizes() {
    const { item, buyInventory } = this.props;
    if (!item.item_sizes || !item.item_sizes.length || 'ps-products' === item.copied_from) {
      return null;
    }

    const inventory_sizes = item.inventory_items.map(ii => ii.size_id);
    const item_sizes = item.item_sizes.filter(s => buyInventory || inventory_sizes.length === 0 || inventory_sizes.includes(s.size_id));
    if (item_sizes.length === 0) {
      return null;
    }
    return (
      <section>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <H5>Sizes</H5>
          <p>{item_sizes.sort((a, b) => sizeSort(a.size_name, b.size_name)).map((s, i) =>
            <span key={s.item_size_id} style={{ textTransform: 'uppercase' }}>{0 === i ? s.size_name : `, ${s.size_name}`}</span>
          )}</p>
          </div>
        </div>
      </section>
    );
  }

  renderSkus() {
    const { item, buyInventory } = this.props;
    if (!item.item_skus || !item.item_skus.length || 'ps-products' !== item.copied_from) {
      return null;
    }

    const inventory_skus = item.inventory_items.map(ii => ii.product_sku_id);
    const item_skus = item.item_skus.filter(
      s => buyInventory || inventory_skus.length === 0 || inventory_skus.includes(s.product_sku_id)
    );
    if (item_skus.length === 0) {
      return null;
    }

    const sku_options = item_skus.reduce(
      (t, item_sku) => ({
        ...t,
        ...item_sku.options.reduce(
          (o, option) => ({
            ...o,
            [option.option_axis]: Array.from(new Set((t[option.option_axis] || []).concat(option.option_name))).sort(
              'size' === option.option_axis ? sizeSort : undefined
            )
          }),
          {}
        )
      }),
      {}
    );
    const axes = Object.keys(sku_options).filter(a => 'dimension' !== a || (sku_options[a].length > 1 && !sku_options['size'])).sort();

    return (
      <section>
        <H5>Options</H5>
        {axes.map(
          axis_name => <div key={axis_name} style={{ display: 'block', marginBottom: '1rem' }}>
            <p style={{ fontWeight: 'bold' }}>{toTitleCase(axis_name)}</p>
            <div style={{ marginTop: '0.5rem', marginBottom: '1rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
              {sku_options[axis_name].map(
                (sku_option, idx) =>
                  <Fragment key={idx}>
                    <span style={{ color: colors.neutrals['100'] }}>{sku_option}</span>
                    {idx < sku_options[axis_name].length - 1 && <span style={{ marginRight: 8, marginLeft: 8, color: colors.neutrals['70'] }}>·</span>}
                  </Fragment>
              )}
            </div>
          </div>
        )}
      </section>
    );
  }

  renderUpCharges() {
    const { item, buyInventory, currency_symbol } = this.props;

    if (!buyInventory && item.inventory_items.length > 0) {
      const min_price = Math.min(...item.inventory_items.map(ii => ii.price));
      const getDescription = (inventory_item) => {
        if (!!inventory_item.product_sku_id) {
          const sku = item.item_skus.filter(s => s.product_sku_id === inventory_item.product_sku_id)[0];
          return !!sku.description ? (sku.description + (!!sku.sku_description ? (' (' + sku.sku_description + ')'): '')) : sku.sku_description;
        } else {
          const size = item.item_sizes.filter(s => s.size_id === inventory_item.size_id)[0];
          const color = item.item_colors.filter(c => c.color_id === inventory_item.color_id)[0];
          if (!!size) {
            if (!!color) {
              return `${size.size_name}/${color.color_name}`;
            }
            return size.size_name;
          } else if (!!color) {
            return color.color_name;
          }
          return null;
        }
      };
      const up_charges = item.inventory_items.filter(ii => ii.price > min_price && !!getDescription(ii));
      if (up_charges.length === 0) {
        return null;
      }
      return (
        <section>
          <H5>Up Charges</H5>
          <p>{up_charges.map((uc, i) =>
            <span key={uc.inventory_item_id}>
              {0 !== i && ', '}
              {getDescription(uc)}: +{currency_symbol}{formatMoney(uc.price - min_price)}
            </span>
          )}</p>
        </section>
      );
    }

    if (!item.item_retail_adjustments || !item.item_retail_adjustments.length) {
      return null;
    }

    const getDescription = adj => {
      if (!!adj.sku) {
        const sku = item.item_skus.filter(s => s.sku === adj.sku)[0];
        return !!sku.description ? (sku.description + (!!sku.sku_description ? (' (' + sku.sku_description + ')'): '')) : sku.sku_description;
      } else {
        return adj.option;
      }
    };
    return (
      <section>
        <H5>Up Charges</H5>
        <p>{item.item_retail_adjustments.map((a, i) =>
          <span key={a.item_retail_adjustment_id}>
            {0 !== i && ', '}
            {getDescription(a)}: +{currency_symbol}{formatMoney(a.adjustment)}
          </span>
        )}</p>
      </section>
    );
  }

  render() {
    const { item, supplier_id, showProductWarnings = false, aggregate, showVendorInfo = true, className = '', style = {}, loading,is_shop_free = 0, itemSizeGuideUrl, } = this.props;

    if(loading) {
      return (
        <div className="small-12 columns text-center">
          <Loading style={{ margin: '50% 0', width: '85px' }}/>
        </div>
      );
    }

    return (
      <div className={`styleguide small-12 columns gallery-item-info ${className}`} style={style}>
        <H1>{item.item_name}</H1>
        {showVendorInfo && item.item_sku && <p style={{ marginBottom: '0.25rem' }}>SKU: {item.item_sku}</p>}
        {showVendorInfo && item.supplier_id !== supplier_id && item.division_name && <p><a target="_blank" href={`/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`} rel="noreferrer">{item.division_name}</a></p>}
        <p dangerouslySetInnerHTML={{ __html: item.item_description}} />
        {showProductWarnings && !!item.product_warnings.length &&
          <ProductWarnings warnings={item.product_warnings} />
        }
        {item.item_warnings.map(iw =>
          <ItemWarning key={iw.item_warning_id} locked={true} item_warning={iw} />
        )}
        {!!item.price_label && <H5>{item.price_label}</H5>}
        {aggregate == 1 && is_shop_free != 1 && this.renderRetailPrice()}
        {this.renderInventory()}
        {(!aggregate || aggregate == '0') && is_shop_free != 1 && this.renderPriceGrid()}
        {is_shop_free != 1 && this.renderUpCharges()}
        {(!aggregate || aggregate == '0') && is_shop_free != 1 && this.renderFixedCosts()}
        {this.renderColors()}
        {this.renderSizes()}
        {this.renderSkus()}
      </div>
    );
  }
}

export default ItemInfo;
